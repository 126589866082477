#banner {
  width: 100%;
  height: 2rem;
  display: grid;
  grid-template-columns:  1fr auto;
  background: var(--accent);

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & a {
    color: var(--accent-contrast-color);
    display: inline;
  }

  & > button {
    padding: 0 5px 0 0;
    margin: 0;
  }
} 