:root {
  --accent: #89b4fa;
  --background: #1e1e2e;
  --color: #cdd6f4;
}
$centerTheme: flex-start;

@import "variables";

@import "font";
@import "buttons";
@import "form";

@import "header";
@import "menu";
@import "logo";
@import "main";
@import "post";
@import "pagination";
@import "footer";
@import "banner";

@import "prism";
@import "syntax";
@import "code";
@import "terms";
@import "gist";
